// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const fr = {
  demo: {
    title: 'France',
    introduction: `Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker.`,
  },
  docs: {
    hi: 'Bonjour',
    description: `Besoin d'aide? \n Consultez notre documentation.`,
    documentation: 'documentation',
  },
  comingSoon: {
    title: 'MDP: Bientôt disponible',
    comingSoon: 'Bientôt disponible!',
    description: 'Nous travaillons actuellement sur cette page!',
  },
  404: {
    title: 'MDP: 404 - Page non trouvée',
    pageNotFound: 'Page non trouvée',
    description: "Oups! La page que vous recherchez n'existe pas.",
  },
  403: {
    title: 'MDP: 403 - Accès refusé',
    noPermission: 'Accès refusé',
    description: "Désolé, vous n'êtes pas autorisé à accéder à cette page.",
  },
  500: {
    title: 'MDP: 500 - Erreur interne du serveur',
    ise: 'Erreur interne du serveur',
    description: 'Une erreur est survenue, veuillez réessayer plus tard.',
  },
  dashboard: {
    title: 'MDP: Accueil',
    lastRefresh: 'Dernier Rafraîchissement',
    monthlyUsers: 'Utilisateurs mensuels',
    monthlyNewUsers: 'Nouveaux utilisateurs mensuels',
    monthlyRevenue: 'Revenus mensuels',
    monthlyOrders: 'Commandes mensuelles',
    todayActiveUsers: 'Utilisateurs actifs de la journée',
    todayNewUsers: 'Nouveaux utilisateurs de la journée',
    todayTotalRevenue: 'Revenus totaux de la journée',
    last3MonthsTopCountries: 'Top 10 des pays durant les 3 derniers mois',
    websiteKpis: 'ICP du site',
    last3months: '3 derniers mois',
    totalUsers: 'Utilisateurs totaux',
    newUsers: 'Nouveaux utilisateurs',
    latestOrders: 'Dernières commandes',
    trafficBySite: 'Trafic par site',
    trafficByDevice: 'Trafic par appareil',
  },
  devices: {
    mobile: 'Mobile',
    desktop: 'Ordinateur',
    tablet: 'Tablette',
    other: 'Autre',
  },
  sites: {
    google: 'Google',
    direct: 'Directe',
    facebook: 'Facebook',
    other: 'Autre',
  },
  statuses: {
    unfulfilled: 'A traiter',
    refunded: 'Remboursée',
    fulfilled: 'Terminée',
    pending: 'En attente',
    cancelled: 'Annulée',
    draft: 'Brouillon',
    completed: 'Complétée',
    partial: 'Partiellement',
    restocked: 'Annulée',
  },
  delayedPaymentDialog: {
    title: 'Paiement différé',
    paymentTerm: 'Terme de paiement',
    receipt: "Paiement dû à l'envoi de la facture",
    net: 'Paiement sous',
    days: 'jours',
    fulfillment: 'Paiement dû lors du traitement de la commande',
    fixed: 'Paiement à date fixe',
    issued_at: "Date d'émission",
    due_at: "Date d'échéance",
  },
  discountDialog: {
    title: 'Ajouter une remise',
    discountType: 'Type de remise',
    value: 'Valeur',
    description: 'Raison de la remise',
    types: {
      fixed_amount: 'Montant fixe',
      percentage: 'Pourcentage',
    },
    requirements: {
      discountType: 'Le type de remise est requis',
      value: 'Une valeur est requise',
    },
  },
  shippingDialog: {
    title: 'Ajouter des frais de livraison',
    shippingType: 'Type de livraison',
    price: 'Prix',
    types: {
      fixed_rate: 'Montant fixe',
      collection: 'Retrait en magasin',
      custom: 'Personnalisé',
    },
  },
  customerListDialog: {
    select: 'Sélectionner un client',
    addNew: 'Nouveau client',
  },
  customProductDialog: {
    title: 'Ajouter un produit personnalisé',
    requirements: {
      title: 'Un titre est requis',
      price: 'Un prix est requis',
      quantity: 'Une quantité est requise',
    },
    fields: {
      title: 'Titre',
      price: 'Prix',
      quantity: 'Quantité',
      taxable: 'Taxable',
      grams: 'Grammes',
    },
  },
  ordersPage: {
    navTitle: 'Commandes',
    title: 'MDP: Commandes',
    heading: 'Commandes',
    dashboard: 'Application',
    searchEmail: 'Rechercher un client par nom ou email',
    clear: 'Supprimer recherche',
    search: 'Rechercher',
    newOrder: 'Nouvelle commande',
    ordersHeading: {
      total: 'Totaux',
      fulfilled: 'Terminées',
      other: 'Autres',
      draft: 'Brouillons',
    },
  },
  orderDetailsPage: {
    title: 'MDP: Commande',
    subtotal: 'Sous Total',
    customerInfo: 'Client',
    shipBy: 'Envoyé par',
    delivery: 'Livraison',
  },
  companyListPage: {
    navTitle: 'Sociétés',
    title: 'MDP: Sociétés',
    heading: 'Liste des sociétés',
    newCompany: 'Nouvelle société',
    currentlyEditing: 'Édition en cours de',
  },
  apeLinkPage: {
    breadcrumbs: 'APE Link',
    navTitle: 'APE Link',
    heading: 'Lien des codes APE',
    title: 'MDP: APE Link',
    addButton: 'Ajouter un code APE',
    addModalTitle: 'Ajouter un code APE',
    linkModalTitle: 'Lier des publications',
    confirm: 'Valider',
    edit: 'Modifier',
    add: 'Ajouter',
    cancel: 'Annuler',
    save: 'Sauvegarder',
    deleteMessage: 'Êtes-vous sûr de vouloir supprimer cette liaisons ?',
    fields: {
      apeCode: 'Code APE',
      publications: 'Publications',
    },
  },
  locationLinkPage: {
    breadcrumbs: 'Location Link',
    navTitle: 'Location Link',
    heading: 'Lien des emplacement',
    title: 'MDP: Location Link',
    addButton: 'Ajouter un emplacement type',
    addModalTitle: 'Ajouter un emplacement type',
    linkModalTitle: 'Lier un emplacement',
    confirm: 'Valider',
    edit: 'Modifier',
    add: 'Ajouter',
    cancel: 'Annuler',
    save: 'Sauvegarder',
    deleteMessage: 'Êtes-vous sûr de vouloir supprimer cette liaisons ?',
    fields: {
      locationsType: 'Emplacements types',
      locations: 'Emplacements',
    },
    updateSuccess: 'Les liens des emplacements ont bien été mis à jour',
  },
  publicationsLinkPage: {
    breadcrumbs: 'Publications Link',
    navTitle: 'Publications Link',
    heading: 'Lier les publications',
    title: 'MDP: Publication Link',
    linkModalTitle: 'Lier une publication',
    confirm: 'Valider',
    edit: 'Modifier',
    add: 'Ajouter',
    delete: 'Supprimer',
    cancel: 'Annuler',
    save: 'Sauvegarder',
    deleteMessage: 'Êtes-vous sûr de vouloir supprimer cette liaisons ?',
    fields: {
      publicationsType: 'Publications Type',
      publications: 'Publications',
    },
  },
  apeCodes: 'Codes APE',
  userListPage: {
    navTitle: 'Utilisateurs',
    title: 'MDP: Liste des utilisateurs',
    heading: 'Liste des utilisateurs',
    users: 'Utilisateurs',
    newUser: 'Nouvel Utilisateur',
    sureDelete: 'Êtes-vous sûr de vouloir supprimer',
    sureDelete2: 'utilisateurs?',
    name: 'Nom',
    role: 'Rôle',
  },
  searchBar: {
    search: 'Rechercher...',
    searchButton: 'Rechercher',
    clear: 'Supprimer la recherche',
  },
  userEditPage: {
    title: 'MDP: Modifier un utilisateur',
    heading: 'Modifier un utilisateur',
  },
  stockListPage: {
    title: 'MDP: Liste des stocks',
    heading: 'Liste des stocks',
    navTitle: 'Stock',
    noLongerExist:
      "L'inventaire de la variante n'existe plus, merci de la créer dans la fiche produit",
  },
  userCreatePage: {
    title: "MDP: Création d'un utilisateur",
    heading: "Création d'un utilisateur",
    createUser: 'Créer',
    updateUser: 'Sauvegarder',
    fields: {
      displayName: "Nom d'affichage",
      firstName: 'Prénom',
      company: 'Société',
      lastName: 'Nom',
      email: 'Adresse email',
      sites: 'Sites',
      stocks: 'Stocks via Site',
      password: 'Mot de Passe',
      role: 'Rôle',
      distributor: 'Distributeur',
      editor: 'Éditeur',
      permissions: 'Permissions',
      locations: 'Locations',
    },
    requirements: {
      displayName: 'Entreprise requise',
      firstName: 'Prénom requis',
      lastName: 'Nom requis',
      sites: 'Au moins un site est requis',
      permissions: 'Au moins une permission est requise',
      password: 'Mot de Passe requis',
      passwordSecurity: 'Le mot de passe doit contenir au moins 6 caractères',
      email: 'Adresse email requise',
      validEmail: "L'adresse email doit être valide",
    },
    authorizedImages: 'Autorisées *.jpeg, *.jpg, *.png, *.gif',
    imageMaxSize: 'avce une taille maximum de',
    createSuccess: "L'utilisateur a été créé avec succès",
    updateSuccess: "L'utilisateur a été mis à jour",
  },
  upload: {
    upload: 'Télécharger',
    update: 'Changer',
    removeAll: 'Tout supprimer',
    uploadFiles: 'Télécharger des fichiers',
    dropOrSelect: 'Dropper ou Selectioner',
    dropOrClick: 'Droppez des fichiers ou cliquez',
    browse: 'ici',
    thruPc: 'pour prendre des fichiers sur votre ordinateur',
  },
  locationsPage: {
    title: 'MDP: Liste des emplacements',
    navTitle: 'Emplacements',
    heading: 'Liste des emplacements',
    name: 'Nom',
    phone: 'Numéro de téléphone',
    address: 'Adresse',
    newLocation: 'Nouvel emplacement',
    quickEdit: 'Édition rapide',
  },
  locationsEditPage: {
    title: 'MDP: Modifier un emplacement',
    heading: 'Modifier un emplacement',
    currentlyEditing: 'Édition en cours de',
  },
  companyCreatePage: {
    title: "MDP: Création d'une société",
    heading: "Création d'une société",
    navTitle: 'Sociétés',
    createUser: 'Créer',
    updateUser: 'Sauvegarder',
    currentlyEditing: 'Édition en cours de',
    fields: {
      name: 'Nom',
      email: 'Email',
      tva: 'TVA',
      siret: 'SIRET',
      rcs: 'RCS',
      apeLink: 'Code APE',
    },
    requirements: {
      name: 'Nom requis',
      email: 'Email requis',
    },
    createSuccess: 'La société à été créée avec succès',
    updateSuccess: 'La société à été mise à jour',
  },
  companyEditPage: {
    title: "MDP: Édition d'une société",
    heading: "Édition d'une société",
    navTitle: 'Sociétés',
  },

  locationCreatePage: {
    title: "MDP: Création d'un emplacement",
    heading: "Création d'un emplacement",
    createUser: 'Créer',
    updateUser: 'Sauvegarder',
    currentlyEditing: 'Édition en cours de',
    fields: {
      name: 'Nom',
      phone: 'Numéro de téléphone',
      address: 'Adresse',
      city: 'Ville',
      zipcode: 'Code postal',
      province: 'Département',
      country: 'Pays',
    },
    requirements: {
      name: 'Nom requis',
      phone: 'Numéro de téléphone requis',
      address: 'Adresse requise',
      city: 'Ville requise',
      zipcode: 'Code postal requis',
      province: 'Département requis',
      country: 'Pays requis',
    },
    error:
      'Erreur lors de la création de l emplacement, veuillez réessayer: Le nom est déjà utilisé',
    createSuccess: "L'emplacement a été créé avec succès",
    updateSuccess: "L' emplacement a été mis à jour",
  },
  customersPage: {
    title: 'MDP: Liste des clients',
    navTitle: 'Clients',
    heading: 'Liste des clients',
    email: 'Email',
    name: 'Nom',
    newCustomer: 'Nouveau Client',
    quickEdit: 'Édition rapide',
    quickCreate: 'Création rapide',
  },
  customersEditPage: {
    title: 'MDP: Modifier un Client',
    heading: 'Modifier un client',
    currentlyEditing: 'Édition en cours de',
  },
  customerCreatePage: {
    title: "MDP: Création d'un client",
    heading: "Création d'un client",
    createUser: 'Créer',
    updateUser: 'Sauvegarder',
    createFailed:
      'Un des champs suivant est déjà utilisé par un autre utilisateur: téléphone, email',
    fields: {
      firstName: 'Prénom',
      lastName: 'nom',
      email: 'Email',
      phone: 'Numéro de téléphone',
      address: 'Adresse',
      city: 'Ville',
      zipcode: 'Code postal',
      province: 'Département',
      country: 'Pays',
    },
    requirements: {
      firstName: 'Prénom requis',
      lastName: 'Nom requis',
      email: 'Adresse email requise',
      validEmail: "L'adresse email doit être valide",
      phone: 'Numéro de téléphone requis',
      address: 'Adresse requise',
      city: 'Ville requise',
      zipcode: 'Code postal requis',
      province: 'Département requis',
      country: 'Pays requis',
    },
    createSuccess: 'Le client a été créé avec succès',
    updateSuccess: 'Le client a été mis à jour',
  },
  productsPage: {
    title: 'MDP: Liste des produits',
    heading: 'Liste des produits',
    navTitle: 'Produits',
    inventory: 'Inventaire',
    price: 'Prix',
    newProduct: 'Nouveau Produit',
    undefined: 'Non défini',
    stocks: {
      in_stock: 'En Stock',
      low_stock: 'Stock bas',
      out_of_stock: 'Plus de stock',
    },
    status: {
      active: 'Actif',
      archived: 'Archivé',
      draft: 'Brouillon',
    },
    alert: {
      infoQueueTitle: 'Information',
      infoQueueDescription:
        'Des produits sont en cours de création ou de modification. Si vous ne les voyez pas ici, vérifiez ultérieurement en actualisant la page.',
    },
  },
  productsEditPage: {
    title: 'MDP: Modification du produit',
    heading: 'Modification du produit',
  },
  invoiceDialog: {
    title: 'Envoie de la facture',
    details: 'Détails nécéssaires pour envoyer la facture',
    subject: 'Facture {{name}}',
    invoiceSent: 'Facture envoyée',
    send: 'Envoyer',
    fields: {
      emailTo: 'Envoyer à',
      emailFrom: 'Envoyé de',
      emailSubject: 'Sujet',
    },
  },
  reserveInventoryDialog: {
    title: "Réservation de l'inventaire",
    date: 'Date',
  },
  productListDialog: {
    title: 'Sélectionner un produit',
    lookingFor: 'Recherche de',
    pleaseWait: 'Merci de patienter...',
    variantTitle: 'Titre du variant',
    barCode: 'Code bar',
  },
  deleteDialog: {
    title: 'Confirmation de suppression',
    dialog: 'Attention vous êtes sur le point de supprimer cet ordre.',
  },
  orderCreatePage: {
    title: "MDP: Création d'une commande",
    tags: 'Balises',
    headingCreate: "Création d'une commande",
    headingEdit: "Édition d'une commande",
    newOrder: "Création d'une commande",
    productHeading: 'Produits',
    addCustomProduct: 'Produit personnalisé',
    editDiscount: 'Modifier la remise',
    addDiscount: 'Ajouter une remise',
    editShipping: 'Modifier les frais de livraison',
    addShipping: 'Ajouter des frais de livraison',
    delete: 'Supprimer',
    shipping: 'Livraison',
    taxes: 'Taxes',
    totalPrice: 'Prix total',
    sendInvoice: 'Envoyer la facture',
    searchProduct: 'Rechercher un produit',
    searchProductPlaceholder: 'Rechercher un produit ou code bar',
    update: 'Sauvegarder',
    completeOrder: 'Terminer la commande',
    markAsPaid: 'Marquer comme payé',
    cancel: 'Annuler la commande',
    createOrder: 'Créer le brouillon',
    details: 'Détails: ',
    subTotal: 'Sous Total',
    fields: {
      tags: 'Balises',
    },
    paymentTerms: {
      net: 'Paiement sous',
      days: 'jours',
      receipt: "Paiement dû à l'envoi de la facture",
      fulfillment: 'Paiement dû lors du traitement de la commande',
      payment_on: 'Paiement dû le:',
      issued_at: "Date d'émission:",
      due_at: "Date d'échéance:",
    },
    reservedInventory: {
      title: "Reservation de l'inventaire",
      reservedInventoryUntil: "L'inventaire est réservé jusqu'au",
    },
  },
  productsCreatePage: {
    title: "MDP: Création d'un produit",
    heading: "Création d'un produit",
    variants: 'Variantes',
    insights: 'Aperçus',
    deleteVariant: 'Supprimer la variante',
    updateSuccess: 'Changements sauvegardés',
    createSuccess: 'Création du produit réussie',
    deleteSuccess: 'Suppression du produit réussie',
    createError: 'Erreur lors de la création du produit, veuillez réessayer',
    price: 'Prix',
    shipping: 'Expédition',
    stock: 'Stock',
    noInsights:
      "Les informations s'afficheront lorsque le produit aura été vendu récemment.",
    insightsDescription:
      '%units unité(s) ont été vendue(s) à %clients client(s) pour %netAmount€ de ventes nettes dans les 90 derniers jours.',
    requirements: {
      title: 'Un titre est requis',
      description: 'Une description est requise',
      editor: 'Un distributeur est requis',
      image: 'Au moins une image est requise',
      price: 'Un prix doit être fixé',
      ean: 'Un EAN (code-barres) est requis',
      sku: 'Un SKU est requis',
    },
    fields: {
      weight: 'Poids',
      weight_unit: 'Unité',
      requires_shipping: 'Livraison',
      template_suffix: "Template d'affichage",
      inventoryPolicy: 'Vendre quand en rupture de stock',
      inventoryManagement: 'Suivre la quantité',
      description: 'Description',
      images: 'Images',
      name: 'Nom du Produit',
      editor: 'Éditeur',
      productType: 'Type de produit',
      status: 'Statut',
      tags: 'Tags',
      title: 'Titre',
      variantTitle: 'Titre du variant',
      location: 'Localisation',
      productCode: 'EAN',
      productSku: 'SKU du produit',
      regularPrice: 'Prix',
      optionName: "Nom de l'option",
      optionValues: "Valeurs de l'option",
    },
    nameOfVariant: 'Nom de la variante',
    options: 'Options',
    addVariant: 'Ajouter une variante',
    addOption: 'Ajouter une option',
    metafields: 'Champs additionels',
    createProduct: 'Créer un produit',
    saveChanges: 'Sauvegarder',
    next: 'Suivant',
    previous: 'Précédent',
    alert: {
      updateQueueTitle: 'Produit en cours de mise à jour',
      updateQueueMessage:
        'Une fois terminée, cette page se rafraîchira automatiquement. Si vous venez de le mettre à jour, vous pouvez quitter cette page sans problème.',
      createQueueTitle: 'Produit en cours de création',
      createQueueMessage:
        'Vous pouvez quitter cette page ou attendre ici, la page se rafraîchira automatiquement une fois le produit créé.',
    },
  },
  accountPage: {
    title: 'MDP: Paramètre du compte',
    heading: 'Paramètre du compte',
    general: 'Général',
    changePassword: 'Sécurité',
    updateSuccess: 'Changements sauvegardés',
    newPassword: 'Nouveau mot de passe',
    confirmPassword: 'Confirmer le mot de passe',
  },
  fulfillmentDialog: {
    title: 'Traitement de la commande',
    trackingNumber: 'Numéro de suivi',
    trackingUrl: 'URL de suivi',
    noShipping:
      'Pas de livraison requise, vous pouvez ignorer les champs ci-dessus.',
  },
  searchNotFound: {
    typeToSearch: 'Tapez pour rechercher...',
    noResults: 'Aucun résultat trouvé',
    noResultsFor: 'Aucun résultat trouvé pour',
    tryAgain: 'Veuillez réessayer',
  },
  siteSelectionPage: {
    helmet: 'MDP: Redirection vers un site',
    questionAction: 'Que souhaitez-vous faire ?',
    goWebsite: 'Voir mon site web',
    goMdpOrder: 'Commander chez MDP',
  },
  shipping: 'Livraison',
  createReturn: 'Créer un retour',
  cancelReturn: 'Annuler le retour',
  closeReturn: 'Cloturer le retour',
  pendingReturn: 'Retour en cours',
  reOpenReturn: 'Rouvrir le retour',
  returned: 'Retourné',
  tags: 'Balises',
  noData: 'Aucune donnée',
  backward: 'Retour',
  remainingItems: 'Articles restants',
  tracking: 'Suivi',
  cancelFulfillment: 'Annuler le traitement',
  fulfilled: 'Article(s) traités',
  fulfill: 'Traiter',
  cancel: 'Annuler',
  add: 'Ajouter',
  remove: 'Supprimer',
  paid: 'Payé',
  address: 'Adresse',
  payment: 'Paiement',
  unpaid: 'Non payé',
  value: 'Valeur',
  logout: 'Se déconnecter',
  unableLogout: 'Erreur merci de réessayer!',
  settings: 'Paramètres',
  sureAction: 'Êtes-vous sûr de vouloir faire ceci ?',
  sureDelete: 'Êtes-vous sûr de vouloir supprimer',
  sureDelete2: 'élément(s) ?',
  edit: 'Modifier',
  selected: 'selectioné(s)',
  delete: 'Supprimer',
  phoneNumber: 'Numéro de téléphone',
  dense: 'dense',
  startDate: 'Date de début',
  endDate: 'Date de fin',
  others: 'Autres',
  dashboardName: 'Application',
  location: 'Emplacement',
  locations: 'Emplacements',
  other: 'autre',
  all: 'Tout',
  total: 'Total',
  visit: 'visite',
  taxes: 'Taxes',
  discount: 'Remise',
  viewAll: 'Voir tout',
  view: 'Voir',
  createdAt: 'Créé le',
  customer: 'Client',
  company: 'Société',
  quickEdit: 'Édition rapide',
  status: 'Statut',
  amount: 'Montant',
  order: 'Commande',
  orders: 'Commandes',
  quantity: 'Quantité',
  app: 'application',
  user: 'Utilisateur',
  list: 'Liste',
  shop: 'boutique',
  blog: 'blog',
  post: 'poste',
  mail: 'e-mail',
  chat: 'discuter',
  cards: 'cartes',
  posts: 'des postes',
  create: 'créer',
  kanban: 'kanban',
  general: 'général',
  banking: 'bancaire',
  booking: 'réservation',
  profile: 'profil',
  account: 'compte',
  product: 'Produit',
  products: 'produits',
  invoice: 'facture',
  details: 'Détails',
  checkout: 'vérifier',
  calendar: 'calendrier',
  analytics: 'analytique',
  ecommerce: 'E-Commerce',
  management: 'management',
  menu_level: 'niveau menu',
  stock: 'Stock',
  home: 'Accueil',
  work: 'Travaux en cours',
  application: 'Application',
  menu_level_2a: 'niveau menu 2a',
  menu_level_2b: 'niveau menu 2b',
  menu_level_3a: 'niveau menu 3a',
  menu_level_3b: 'niveau menu 3b',
  menu_level_4a: 'niveau menu 4a',
  menu_level_4b: 'niveau menu 4b',
  item_disabled: 'élément désactivé',
  item_label: `étiquette de l'article`,
  item_caption: `légende de l'article`,
  item_external_link: `lien externe de l'article`,
  description: 'la description',
  other_cases: 'autres cas',
  update: 'Mettre à jour',
  item_by_roles: 'élément par rôles',
  only_admin_can_see_this_item: `seul l'administrateur peut voir cet élément`,
  goToHome: "Aller à l'accueil",
  welcome_to_MDP: 'Bienvenue sur la solution MDP !',
  login: 'Se connecter',
  email_adress: 'Adresse email',
  password: 'Mot de passe',

  waiting_for_supplier: 'En attente du fournisseur',
  in_preparation: 'En cours de préparation',
  arrival_in_progress: 'Arrivage en cours',
  waiting_for_regrouping: 'En attente de regroupement',
  pre_order: 'Précommande',
  to_ship: 'À expédier',

  websites: 'Sites',
  myWebsite: 'Mon site web',
  mdpOrder: 'Commande MDP',
  refound: 'Remboursement',

  cancelDialog: {
    refund: 'Annulation',
    title: 'Annulation de la commande',
    amount: 'Montant',
    cancelSent: 'La commande a bien été remboursée',
    save: 'Annulation de la commande',
    amountExceeded:
      'Vous ne pouvez pas rembourser plus que le montant de la commande.',
    amountRequired:
      'Vous devez saisir le montant du remboursement (il peut être de 0).',
  },
  axiosError: 'Une erreur est survenue',

  publication: 'Publication',
  salesMethod: 'Méthode de vente',
  close: 'Fermer',
  apeCode: 'Code APE',
  companies: 'Société(s)',
  publicationExceptions: 'Canaux de vente bannis',
  method: 'Méthode',

  supportListPage: {
    navTitle: 'Support',
  },

  upComingAppsPage: {
    title: 'MDP: Applications',
    appUpCommingList: 'Les applications à venir...',
    appList: 'Liste des applications disponibles...',
    description:
      'Découvrez la liste des applications disponibles sur la plateforme MDP.',
    web: {
      title: 'Site Internet',
      navTitle: 'Site Internet',
      description: `MDP Site Web change la façon dont les gens envisagent la conception d'un site web. Grâce à son interface intuitive et conviviale, vous pouvez facilement créer, gérer et personnaliser votre site web.
        Votre site Internet ou application sur-mesure, en format vitrine ou e-commerce (vente en ligne, click & collect, livraison locale), sous marque blanche avec un référencement local optimisé pour votre activité.`,
      liveChatApp: {
        title: 'Live Chat',
        description: 'Discutez directement avec vos visiteurs en ligne',
      },
      blogApp: {
        title: 'Blog',
        description:
          'Publiez des articles, annonces, nouvelles et créer des vente',
      },
      forumApp: {
        title: 'Forum',
        description: 'Gérer un forum avec des FAQ et Q&R',
      },
    },
    sale: {
      title: 'Ventes',
      navTitle: 'Ventes',
      description:
        'MDP Vente est le véritable outil centré sur le client pour tous vos besoins commerciaux. Suivez vos pistes, obtenez des prévisions précises et concentrez-vous sur l’essentiel : conclure des opportunités.',
      subscription: {
        title: 'Abonnements',
        description: 'Factures récurrentes et renouvellements',
      },
      rental: {
        title: 'Location',
        description: 'Gérer des contrats, des livraisons et des retours',
      },
      CRM: {
        title: 'CRM',
        description: 'Suivez des pistes et saisissez les opportunités',
      },
      sales: {
        title: 'Ventes',
        description: 'Du devis à la facture',
      },
      POS: {
        title: 'Point de vente',
        description: 'Interface PDV pour magasin',
      },
    },
    finance: {
      title: 'Finance',
      navTitle: 'Finance',
      description:
        'MDP finance est un logiciel moderne de gestion. Vivez le travail différemment et ne subissez plus les frustrations liées à la lenteur des interfaces, à l’encombrement des boîtes aux lettres électroniques et à l’accumulation de données.',
      accounting: {
        title: 'Comptabilité',
        description: 'Gérer la comptabilité financière et analytique',
      },
      document: {
        title: 'Documents',
        description: 'Gestion des documents',
      },
      spreadsheet: {
        title: 'Feilleur de calcul',
        description: 'Document sous feuille de calcul',
      },
      sign: {
        title: 'Signature',
        description: 'Signez des documents en ligne',
      },
      facturing: {
        title: 'Facturation',
        description: 'Factures & paiements',
      },
      expenses: {
        title: 'Note de frais',
        description: 'Gérer les dépenses de vos employés',
      },
    },
    inventory: {
      title: 'Inventaire & fabrication',
      navTitle: 'Inventaire',
      description:
        'Réduisez les ruptures de stock, accélérez les opérations, optimisez les routes et obtenez une visibilité en temps réel avec l’application de gestion d’entrepôt de MDP.',
      manufacturing: {
        title: 'Fabrication',
        description: 'Ordres de fabrication & nomenclatures',
      },
      PLM: {
        title: 'PLM',
        description: 'Gestion du cycle de vie d’un produit',
      },
      quality: {
        title: 'Qualité',
        description: 'Contrôlez la qualité de vos produits',
      },
      stock: {
        title: 'Inventaire',
        description: 'Gérez vos stocks et vos activités logistiques',
      },
      purchase: {
        title: 'Achats',
        description: 'Bons de commande, appels d’offres et contrats',
      },
      maintenance: {
        title: 'Maintenance',
        description: 'Assurez un suivi de l’équipement et gérez les demandes',
      },
    },
    hr: {
      title: 'Ressources Humaines',
      navTitle: 'RH',
      description:
        'L’application Employés de MDP est bien plus qu’un simple registre du personnel. C’est le point central d’une suite complète des fonctionnalités RH.',
      employee: {
        title: 'Employés',
        description: 'Centralisez les informations de vos employés',
      },
      recruitment: {
        title: 'Recrutement',
        description: 'Contrôlez votre pipeline de recrutement',
      },
      leaves: {
        title: 'Congés',
        description: 'Allouez les jours de congé et suivez les demandes',
      },
    },
    marketing: {
      title: 'Marketing',
      navTitle: 'Marketing',
      description:
        'Découvrez des possibilités marketing illimitées grâce à des flux de travail entièrement automatisés, faciles d’usages et autonomes.',
      email: {
        title: 'Email & SMS',
        description: 'Créez vos mails et SMS avec suivi intégré',
      },
      automation: {
        title: 'Automatisation marketing',
        description: 'Concevez vos campagnes',
      },
      social: {
        title: 'Social',
        description:
          'Gérez vos réseaux sociaux et les visiteurs de votre site Internet',
      },
      event: {
        title: 'Événement',
        description: 'Publiez vos évènements',
      },
      poll: {
        title: 'Sondage',
        description: 'Envoyez vos enquêtes ou partagez les en direct',
      },
    },
    services: {
      title: 'Services',
      navTitle: 'Services',
      description:
        'MDP service la plateforme qui simplifie le quotidien de votre équipe, pour qu’elle puisse mieux assister vos clients. Dites adieu à la complexité et entrez dans une nouvelle ère d’assistance transparente, efficace et centrée sur le client.',
      timeSheet: {
        title: 'Feuille de temps',
        description:
          'Suivez le temps que vos employés consacrent à leurs tâches',
      },
      onSite: {
        title: 'Services sur site',
        description:
          'Programmez et suivez les opérations sur place, le temps et le matériel',
      },
      support: {
        title: 'Assistance',
        description:
          'Suivez, établissez des priorités et résolvez les problèmes des clients',
      },
      schedule: {
        title: 'Planification',
        description: 'Gérez l’emploi du temps de vos employés',
      },
      rendezvous: {
        title: 'Rendez-vous',
        description: 'Planifier des réunions dans votre agendas',
      },
      project: {
        title: 'Projet',
        description: 'Organisez et planifiez vos projets',
      },
    },
    productivity: {
      title: 'Productivité',
      navTitle: 'Productivité',
      description:
        'MDP Productivité permet à vos équipes de communiquer et collaborer en toute simplicité.',
      validation: {
        title: 'Validation',
        description: 'Gérer et valider des demandes d’approbation',
      },
      import: {
        title: 'Importation',
        description: 'Importez vos catalogues et les créer en un éclair',
      },
      chat: {
        title: 'Discussion',
        description: 'Chat, passerelle de messagerie et canaux privés',
      },
    },
    footer: {
      line1: 'Un besoin, une app ?',
      line2: 'Notre équipe de développement est là pour vous la créer !',
    },
  },

  upComingApps: 'Applications à venir',

  permissionsTypes: {
    list: 'Voir',
    edit: 'Modifier',
    create: 'Créer',
    delete: 'Supprimer',

    product: 'Produit',
    customer: 'Client',
    location: 'Emplacement',
    company: 'Entrerpise',
    stock: 'Stock',
    order: 'Commande',
    user: 'Utilisateur',
    publication: 'Publication',
    apeLink: 'Lien APE',
    site: 'Site',
    dashboard: 'Dashboard',
  },

  noDataError: `Aucune donnée n'a été trouvée...`,
  reloadPage: 'Actualiser la page',
};

export default fr;

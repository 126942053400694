// config

import { AuthUserType } from '../auth/types';

export type IRole = {
  label: string;
  value: number;
};

export const ROLES = {
  ADMIN: {
    label: 'ADMIN',
    value: 3,
  },
  MANAGER: {
    label: 'MANAGER',
    value: 2,
  },
  USER: {
    label: 'USER',
    value: 1,
  },
  CLIENT: {
    label: 'CLIENT',
    value: 0,
  },
};

export type KeyOfRoles = keyof typeof ROLES;

export type IPermission = {
  label: string;
  role: IRole;
};

export const PERMISSIONS = {
  productCreate: {
    label: 'product.create',
    role: ROLES.MANAGER,
  },
  productEdit: {
    label: 'product.edit',
    role: ROLES.MANAGER,
  },
  productList: {
    label: 'product.list',
    role: ROLES.MANAGER,
  },
  productDelete: {
    label: 'product.delete',
    role: ROLES.MANAGER,
  },
  customerCreate: {
    label: 'customer.create',
    role: ROLES.MANAGER,
  },
  customerEdit: {
    label: 'customer.edit',
    role: ROLES.MANAGER,
  },
  customerList: {
    label: 'customer.list',
    role: ROLES.MANAGER,
  },
  customerDelete: {
    label: 'customer.delete',
    role: ROLES.MANAGER,
  },
  locationCreate: {
    label: 'location.create',
    role: ROLES.MANAGER,
  },
  locationEdit: {
    label: 'location.edit',
    role: ROLES.MANAGER,
  },
  locationList: {
    label: 'location.list',
    role: ROLES.MANAGER,
  },
  locationDelete: {
    label: 'location.delete',
    role: ROLES.MANAGER,
  },
  companyCreate: {
    label: 'company.create',
    role: ROLES.MANAGER,
  },
  companyEdit: {
    label: 'company.edit',
    role: ROLES.MANAGER,
  },
  companyList: {
    label: 'company.list',
    role: ROLES.MANAGER,
  },
  companyDelete: {
    label: 'company.delete',
    role: ROLES.MANAGER,
  },
  stockEdit: {
    label: 'stock.edit',
    role: ROLES.MANAGER,
  },
  stockList: {
    label: 'stock.list',
    role: ROLES.MANAGER,
  },
  orderCreate: {
    label: 'order.create',
    role: ROLES.MANAGER,
  },
  orderEdit: {
    label: 'order.edit',
    role: ROLES.MANAGER,
  },
  orderList: {
    label: 'order.list',
    role: ROLES.MANAGER,
  },
  orderDelete: {
    label: 'order.delete',
    role: ROLES.MANAGER,
  },
  userCreate: {
    label: 'user.create',
    role: ROLES.ADMIN,
  },
  userEdit: {
    label: 'user.edit',
    role: ROLES.ADMIN,
  },
  userList: {
    label: 'user.list',
    role: ROLES.ADMIN,
  },
  publicationList: {
    label: 'publication.list',
    role: ROLES.ADMIN,
  },
  publicationEdit: {
    label: 'publication.edit',
    role: ROLES.ADMIN,
  },
  apeLinkList: {
    label: 'apeLink.list',
    role: ROLES.ADMIN,
  },
  apeLinkEdit: {
    label: 'apeLink.edit',
    role: ROLES.ADMIN,
  },
  apeLinkDelete: {
    label: 'apeLink.delete',
    role: ROLES.ADMIN,
  },
  userDelete: {
    label: 'user.delete',
    role: ROLES.ADMIN,
  },
  siteList: {
    label: 'site.edit',
    role: ROLES.ADMIN,
  },
  dashboardView: {
    label: 'dashboard.view',
    role: ROLES.MANAGER,
  },
};

export function hasPermission(
  user: AuthUserType,
  permission: IPermission | IRole
) {
  return (
    user?.role === ROLES.ADMIN.label ||
    user?.permissions.find(
      (_permission: string) => _permission === permission.label
    ) !== undefined
  );
}

export function getRoleFromUser(user: AuthUserType) {
  return ROLES[user?.role as KeyOfRoles];
}

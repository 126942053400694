// @mui
import {
  IconButton,
  Link,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
// components
import { useBoolean } from 'src/hooks/useBoolean';
// utils
// @types
import { IProduct } from '../../../@types/product';
import { useAuthContext } from '../../../auth/useAuthContext';
import Iconify from '../../../components/iconify';
import Image from '../../../components/image';
import Label from '../../../components/label';
import { useLocales } from '../../../locales';
import { PERMISSIONS, hasPermission } from '../../../utils/permissions';
import StockQuickEditForm from './StockQuickEditForm';

// ----------------------------------------------------------------------

type Props = {
  row: IProduct;
  location: string;
};

export default function StockTableRow({ row, location }: Props) {
  const cover = row.cover || row.images?.[0]?.src;
  const quickEdit = useBoolean();
  const productInventoriesForLocation = row.product_inventories?.filter(
    (e) => e.location_id === location
  );

  const [data, setData] = useState<IProduct>({
    ...row,
    available:
      productInventoriesForLocation?.reduce(
        (acc, cur) => acc + (cur?.available || 0),
        0
      ) || row.available,
  });

  console.log(productInventoriesForLocation, data?.variants);

  const skus = data.variants?.map((v) => v.sku);

  const title = `${data?.title}`;

  const skuLabel = skus
    ?.filter?.((item, index) => skus.indexOf(item) === index)
    ?.join(', ');

  const inventoryType =
    data.available > 10
      ? 'in_stock'
      : data.available > 0
      ? 'low_stock'
      : 'out_of_stock';

  const { user } = useAuthContext();

  const { translate } = useLocales();

  return (
    <>
      <TableRow hover>
        <TableCell
          width="50%"
          onClick={
            hasPermission(user, PERMISSIONS.stockEdit)
              ? quickEdit.onTrue
              : undefined
          }
          sx={{
            cursor: hasPermission(user, PERMISSIONS.stockEdit)
              ? 'pointer'
              : 'default',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Image
              disabledEffect
              visibleByDefault
              alt={title}
              src={cover}
              sx={{ borderRadius: 1.5, width: 48, height: 48, flexShrink: 0 }}
            />
            <Link
              title={title}
              color="inherit"
              variant="subtitle2"
              onClick={quickEdit.onTrue}
              sx={{ cursor: 'pointer' }}
            >
              {title}
            </Link>
          </Stack>
        </TableCell>

        <TableCell
          width="10%"
          onClick={
            hasPermission(user, PERMISSIONS.stockEdit)
              ? quickEdit.onTrue
              : undefined
          }
          sx={{
            cursor: hasPermission(user, PERMISSIONS.stockEdit)
              ? 'pointer'
              : 'default',
          }}
        >
          <Typography noWrap variant="body2">
            {`${translate(`productsPage.status.${data.status}`)}`}
          </Typography>
        </TableCell>

        <TableCell
          width="20%"
          onClick={
            hasPermission(user, PERMISSIONS.stockEdit)
              ? quickEdit.onTrue
              : undefined
          }
          sx={{
            cursor: hasPermission(user, PERMISSIONS.stockEdit)
              ? 'pointer'
              : 'default',
          }}
        >
          <Typography variant="body2">{skuLabel}</Typography>
        </TableCell>

        <TableCell
          align="left"
          width="15%"
          onClick={
            hasPermission(user, PERMISSIONS.stockEdit)
              ? quickEdit.onTrue
              : undefined
          }
          sx={{
            cursor: hasPermission(user, PERMISSIONS.stockEdit)
              ? 'pointer'
              : 'default',
          }}
        >
          <Label
            variant="soft"
            color={
              (inventoryType === 'out_of_stock' && 'error') ||
              (inventoryType === 'low_stock' && 'warning') ||
              'success'
            }
            sx={{ textTransform: 'capitalize' }}
          >
            {`${data.available} ${translate('product')}s`}
          </Label>
        </TableCell>

        {hasPermission(user, PERMISSIONS.stockEdit) && (
          <TableCell
            align="right"
            sx={{ px: 1, whiteSpace: 'nowrap' }}
            width="5%"
          >
            <Tooltip
              title={`${translate('customersPage.quickEdit')}`}
              placement="top"
              arrow
            >
              <IconButton
                color={quickEdit.value ? 'inherit' : 'default'}
                onClick={quickEdit.onTrue}
              >
                <Iconify icon="solar:pen-bold" />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>

      {hasPermission(user, PERMISSIONS.stockEdit) && (
        <StockQuickEditForm
          currentProduct={data}
          currentLocation={location}
          open={quickEdit.value}
          onClose={(_data) => {
            if (typeof _data !== 'number') {
              quickEdit.onFalse();
              return;
            }

            setData({
              ...data,
              available: _data,
            });

            quickEdit.onFalse();
          }}
        />
      )}
    </>
  );
}
